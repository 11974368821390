import { gql } from '@apollo/client';

export const FRAGMENT_SHOP = gql`
  fragment FRAGMENT_SHOP on CompanyShop {
    id
    createdAt
    locale
    active
    name
    type
    currency
    hostname
    customDomain
    branchType
    logoMedia {
      src
      optimizedSrc(width: 256)
    }
    stockWarehouse {
      id
    }
    company {
      id
    }
    address {
      id
      person
      tel
    }
    metadata {
      key
      value
    }
  }
`;

export const GET_QUERY = gql`
  query (
    $cursor: Int
    $limits: Int
    $filter: UserShopsFilterInput
    $query: String
    $sortBy: [SorterInput!]
    $startedAt: AWSDateTime!
    $startedThru: AWSDateTime!
  ) {
    me {
      ... on User {
        shops(cursor: $cursor, limits: $limits, filter: $filter, query: $query, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_SHOP
            report {
              turnover(startedAt: $startedAt, startedThru: $startedThru)
            }
          }
        }
      }
    }
  }
  ${FRAGMENT_SHOP}
`;
export const GET_BASIC_QUERY = gql`
  query ($cursor: Int, $limits: Int, $filter: UserShopsFilterInput, $query: String, $sortBy: [SorterInput!]) {
    me {
      ... on User {
        shops(cursor: $cursor, limits: $limits, filter: $filter, query: $query, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_SHOP
          }
        }
      }
    }
  }
  ${FRAGMENT_SHOP}
`;
