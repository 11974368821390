import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { translate } from 'shared/translate';
import { GET_QUERY } from './query';
import { getCommonColumns } from '../../../shared';
import ActiveSwitcher from './ActiveSwitcher';
export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/service_locations/new',
    selectionMode: false,
    getRowLink: ({ id, name }) => ({
      pathname: `/service_locations/${id}`,
      state: { title: name },
    }),
    fields: [
      {
        width: 20,
        title: translate.image,
        fieldName: 'medias',
        type: 'medias',
      },
      {
        title: translate.name,
        fieldName: 'name',
        sortBy: 'name',
        filter: 'name',
        filterType: 'text',
      },
      ...getCommonColumns(),
      {
        title: translate.activation,
        width: 100,
        render: (data) => {
          const { id, active } = data || {};
          return <ActiveSwitcher id={id} active={active} onCompleted={this.refetch} />;
        },
        noLink: true,
        filter: 'active',
        type: 'bool',
      },
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = (data) => {
    return data?.node?.serviceLocations;
  };
}
