import React, { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Icon } from '../IconRender';
import { translate } from '../../shared/translate';
import { ReactComponent as IconMagicPen } from '../../assets/magicPen.svg';
import { Close } from '@material-ui/icons';
import MagicMediaNewBackgroundStep from './MagicMediaNewBackgroundStep';
import MagicMediaRemoveBackgroundStep from './MagicMediaRemoveBackgroundStep';

const useStyles = makeStyles((theme) => ({
  magicBtn: {
    zIndex: 1,
    padding: '5px 10px',
    background: 'linear-gradient(144deg, #355F7E 0%, #00E0CA 100%)',
    border: 'none',
    borderRadius: '30px',
    color: '#fff',
    '&:hover': {
      background: 'linear-gradient(144deg, #2b3e4d 0%, #00E0CA 100%)',
    },
  },
  btnIcon: {
    height: '16px',
    width: '16px',
  },
  dialogBox: {
    borderRadius: '10px',
    background: 'rgba(255, 255, 255, 0.75)',
    boxShadow: '0px 0px 38px 20px rgba(255, 255, 255, 0.70) inset',
    backdropFilter: 'blur(3px)',
    height: 650,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  dialogTitleRow: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogHeroBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: -16,
  },
  dialogHero: {
    padding: '0.8rem 2rem',
    width: '80%',
    borderRadius: '55px',
    border: 'solid #fff 1px',
    background: 'rgb(91, 125, 150)',
    backgroundImage: 'linear-gradient(90deg, rgba(91, 125, 150, 1) 0%, rgba(0, 224, 202, 1) 80%)',
    textAlign: 'center',
  },
  dialogHeroTitle: {
    color: '#fff',
    fontSize: '36px',
    fontWeight: 'bold',
    margin: 0,
    lineHeight: 1.2,
  },
  dialogHeroSubTitle: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bold',
    margin: 0,
    lineHeight: 1.2,
  },
  magicDialogBtnGroup: {
    display: 'flex',
    gap: '40px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  magicBgBtn: {
    width: '250px',
    height: '250px',
    border: 'solid 20px #FFF',
    color: '#1F4C6D',
    fontSize: '18px',
    background: `url(${require('../../assets/magicBg.png')}) no-repeat center center `,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  removeBgBtn: {
    width: '250px',
    height: '250px',
    border: 'solid 20px #FFF',
    color: '#1F4C6D',
    fontSize: '18px',
    background: `url(${require('../../assets/removeBg.png')}) no-repeat center center `,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
}));

const MagicMediaDialogButton = ({ loading: _loading, disabled, value, onChange = (_) => _ }) => {
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState('');
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setCurrentStep('');
  };

  const handleGoBack = () => {
    setCurrentStep('');
  };

  const handleRemoveBgClick = () => {
    setCurrentStep('removeBg');
  };

  const handleNewBgClick = () => {
    setCurrentStep('newBg');
  };

  return (
    <>
      <Button
        disabled={disabled}
        className={`${classes.magicBtn} magic-media-dialog-button`}
        type={'button'}
        startIcon={<Icon type={'svg'} icon={IconMagicPen} viewBox={'0 0 18 17'} className={classes.btnIcon} />}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Typography
          style={{
            fontSize: '9px',
            fontWeight: '600',
          }}
          color={'inherit'}
        >
          {translate.ai_image || '智能圖片'}
        </Typography>
      </Button>
      <Dialog
        PaperProps={{
          style: {
            background: 'none',
          },
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
        fullWidth={'md'}
        maxWidth={'md'}
      >
        <Box className={classes.dialogBox}>
          <DialogTitle id="customized-dialog-title">
            <Box className={classes.dialogTitleRow}>
              <IconButton
                component="span"
                onClick={() => {
                  handleClose();
                }}
              >
                <Close />
              </IconButton>
            </Box>
            <Box className={classes.dialogHeroBox}>
              <Box className={classes.dialogHero}>
                <Typography className={classes.dialogHeroTitle}> Magic Image </Typography>
                <Typography className={classes.dialogHeroSubTitle}> 產品圖片 </Typography>
              </Box>
              <Box>
                <Typography
                  style={{
                    marginTop: '15px',
                    fontSize: '18px',
                    color: '#1F4C6D',
                  }}
                >
                  {currentStep === '' ? '請選擇圖片格式' : '請確認圖片效果'}
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <Box
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {currentStep === '' && (
              <Box className={classes.magicDialogBtnGroup}>
                <Button onClick={handleRemoveBgClick} className={classes.removeBgBtn}>
                  產品智能去背
                </Button>
                <Button onClick={handleNewBgClick} className={classes.magicBgBtn}>
                  產品智能背景
                </Button>
              </Box>
            )}
            {currentStep === 'removeBg' && (
              <MagicMediaRemoveBackgroundStep
                value={value}
                handleGoBack={handleGoBack}
                handleClose={handleClose}
                onChange={onChange}
              />
            )}
            {currentStep === 'newBg' && (
              <MagicMediaNewBackgroundStep
                value={value}
                handleGoBack={handleGoBack}
                handleClose={handleClose}
                onChange={onChange}
              />
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default MagicMediaDialogButton;
