import React from 'react';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';
import FormCard from './Cards/FormCard';
import { toast } from 'shared/toast';
import FormToolbar from 'components/FormToolbar';
import ActivityIndicator from 'components/ActivityIndicator';
import QueryParamsWrapper from 'components/QueryParamsWrapper';
import { translate } from 'shared/translate';
import { Formik } from 'formik';
import { client } from 'shared/apollo';
import errorParser from 'shared/errorParser';
import { Box, Grid } from '@material-ui/core';
import Taber from '../Taber';
import AccordionWrapper from 'components/FormPageMaker/AccordionWrapper';
import { makerParseFunctionValue, sleep } from '../../shared';
import HashWatcher from '../HashWatcher';
import DirtyConflictChecker, { DirtyListener } from './DirtyConflictChecker';

export default class FormPageMaker extends QueryParamsWrapper {
  values = {};
  onSubmitTabCache = 0;
  constructor(props) {
    super(props);
    const { match: { params = {} } = {}, location } = this.props,
      state = (location || {}).state || {},
      id = params.id === 'new' ? undefined : params.id;

    /* You Should Overwrite this state setting */
    this.state = {
      id,
      action: id ? 'update' : 'create',
      gql: {
        prepare: '',
        submit: '',
        remove: '',
      },
      editMode: false,
      tabs: [
        // {
        //   name: 'Tab1',
        //   cards: [],
        // },
        // {
        //   name: 'Tab2',
        //   cards: [],
        // },
      ],
      cards: [
        {
          name: 'Card 1',
          fields: [
            {
              customErrorMessage: 'please input text with 3 < length < 255',
              label: 'name',
              type: 'text',
              name: 'name',
              required: true,
              maxLength: 255,
              render: ({ values, handleChange }) => 123,
              remarks: '123',
            },
          ],
        },
      ],
      enableReinitialize: false,
      currentTab: +this.getQueryParam('tab') || 0,
      formDisabled: false,
      dirty: false,
      dirtyKey: 0,
      conflictReloaded: false,
      key: 0,
      ...state,
    };
  }
  submittedCount = 0;

  submit = async (values) => {
    return '';
  };

  getInitialData(data, loading) {
    return {};
  }

  getActiveTab() {
    const { currentTab } = this.state;
    if (currentTab !== null && currentTab !== undefined) return currentTab;
    return this.onSubmitTabCache || 0;
  }

  remove = async (actions) => {
    const {
      id,
      gql: { remove },
    } = this.state;
    await client.mutate({
      mutation: remove,
      variables: { id },
    });
  };

  onSubmitClick = async (values, { setSubmitting, ...actions }) => {
    const { history, location: { pathname } = {} } = this.props;
    const { id } = this.state;

    this.submittedCount = this.submittedCount + 1;
    try {
      ActivityIndicator.show();
      setSubmitting(true);
      const newId = await this.submit(this.values, { ...actions, setSubmitting });
      toast.success(!!id ? translate.update_success_msg : translate.create_success_msg);
      this.setState({ currentTab: this.onSubmitTabCache });
      if (newId === true) {
        try {
          history.goBack();
        } catch (e) {}
      } else if (newId === false) {
      } else if (!!newId) {
        this.setState({ id: newId });
        try {
          history.replace(pathname.replace('new', newId));
        } catch (e) {}
      }
    } catch (e) {
      console.log(e);
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
      ActivityIndicator.hide();
    }
  };

  onCancelClick() {
    const { history } = this.props;
    try {
      history.goBack();
    } catch (e) {}
  }

  async onRemoveClick(actions) {
    const { setSubmitting } = actions;
    const { history } = this.props;
    try {
      setSubmitting(true);
      ActivityIndicator.show();
      if (await this.remove(actions)) toast.success(translate.remove_success_msg);

      try {
        history.goBack();
      } catch (e) {}
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setSubmitting(false);
      ActivityIndicator.hide();
    }
  }

  onHashChange = (hash) => {
    try {
      const { tabs = [] } = this.state;
      const tabIndex = tabs.findIndex(({ id, cards = [] }) => {
        return (
          id === hash ||
          cards.find(({ id, fields = [] }) => {
            return (
              id === hash ||
              fields.find(({ id }) => {
                return id === hash;
              })
            );
          })
        );
      });
      if (!!~tabIndex) {
        this.setState({
          currentTab: tabIndex,
        });
        setTimeout(
          window.requestAnimationFrame(function () {
            const el = document.getElementById(decodeURI(hash));
            !!el && el.scrollIntoView();
          }),
          100,
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  onCompleted(data) {}

  getExtraFetchVariables() {
    return {};
  }

  getFetchPolicy() {
    return 'network-only';
  }

  getFetchResult(result) {
    const { loading, data } = result || {};
    return { loading: loading && !data, data };
  }

  renderExtraButtons(actions) {
    return null;
  }

  render() {
    const { id, gql: { prepare } = {} } = this.state;
    if (!!prepare)
      return (
        <Query
          fetchPolicy={this.getFetchPolicy.bind(this)()}
          query={prepare}
          variables={{ id, ...this.getExtraFetchVariables() }}
          onCompleted={this.onCompleted.bind(this)}
          skip={!id}
        >
          {this.renderContent.bind(this)}
        </Query>
      );
    return this.renderContent.bind(this)({});
  }

  renderContent(result) {
    const { className } = this.props;
    const { editMode } = this.state;
    const { loading, data } = this.getFetchResult(result);

    this.initialValues = this.getInitialData(data || {}, loading);
    return (
      <div className={className}>
        {this.renderForm({ ...this.initialValues, __disabled: !!editMode && !!this.initialValues?.id }, loading)}
      </div>
    );
  }

  renderCard(card, _actions, i) {
    const { formDisabled } = this.state;
    const actions = {
      ..._actions,
      disabled: formDisabled || _actions?.isSubmitting || _actions?.loading || _actions?.values?.__disabled,
      isSubmitting: formDisabled || _actions.isSubmitting || _actions?.values?.__disabled,
    };

    if (typeof card === 'function' && (!!card.prototype.isReactComponent || String(card).includes('createElement'))) {
      const ControlModule = card;
      return <ControlModule key={i} state={this.state} {...this.props} {...actions} />;
    }

    const { name, type = 'form', render, fields = [], field = {} } = card;
    if (render) return <div key={i}>{render(actions)}</div>;
    const props = { key: i, name, field, fields, actions };
    switch (type) {
      case 'form':
        return <FormCard {...props} />;
      default:
        return null;
    }
  }

  renderOthers(data, actions) {
    return null;
  }

  renderForm(initialValues, loading) {
    const {
      key,
      cards,
      enableReinitialize,
      tabs,
      currentTab,
      disableToolBar = false,
      dirty,
      dirtyKey,
      conflictReloaded,
    } = this.state;
    return (
      <>
        <DirtyConflictChecker
          dirty={dirty}
          conflictReloaded={conflictReloaded || this.submittedCount > 0}
          initialData={initialValues}
          onConflictReload={() => {
            this.setState({ conflictReloaded: true, dirtyKey: dirtyKey + 1 });
          }}
        />
        <Formik
          key={`${key}-${loading ? 0 : 1}-${dirtyKey}`}
          enableReinitialize={enableReinitialize}
          initialValues={initialValues}
          onSubmit={this.onSubmitClick}
        >
          {(actions) => (
            <>
              <DirtyListener dirty={actions.dirty} onDirty={() => this.setState({ dirty: true })} />
              <HashWatcher onHashChange={this.onHashChange} />
              {this.renderTaber({ ...actions, loading })}
              <form
                noValidate={true}
                onSubmit={async (e) => {
                  const required = document.querySelectorAll('input[required]');
                  required.forEach((element) => {
                    // eslint-disable-next-line
                    if (element.value.trim() == '') {
                      element.placeholder = translate.please_enter;
                      element.style.color = 'red';
                      try {
                        const customErrorMessage = getCustomErrorMessage(element);
                        if (customErrorMessage) {
                          element.setCustomValidity(customErrorMessage);
                        }
                      } catch (e) {}
                    } else {
                      element.placeholder = null;
                      element.style.color = null;
                    }
                  });

                  function getCustomErrorMessage(element) {
                    const m = element.getAttribute('customerrormessage');
                    if (m) return m;
                    const parent = element.parentElement;
                    if (parent) return getCustomErrorMessage(parent);
                    return undefined;
                  }

                  e.preventDefault();
                  e.stopPropagation();

                  this.values = actions.values;
                  const form = e.target;
                  try {
                    this.onSubmitTabCache = currentTab;
                    this.setState({ currentTab: undefined });
                    await new Promise((resolve) => setTimeout(resolve, 10));
                    if (!form.checkValidity()) {
                      form.reportValidity();
                    } else {
                      actions.handleSubmit(e);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                <Grid container direction={'row'}>
                  {(!tabs || tabs.length === 0) &&
                    cards.map((card, i) => {
                      return (
                        <Grid key={i} item xs={card?.xs || 12} md={card?.md || 12}>
                          <AccordionWrapper
                            square={true}
                            disabledBackground={card?.disabledBackground}
                            expanded={true}
                            padding={card?.noPadding ? 0 : card?.padding}
                          >
                            <Box width={'100%'}>{this.renderCard(card, { ...actions, loading }, i)}</Box>
                          </AccordionWrapper>
                        </Grid>
                      );
                    })}
                  {!!tabs &&
                    tabs.map(({ cards = [], ..._tab }, i) => {
                      const {
                        padding,
                        // name,
                        noPadding,
                        disabledBackground,
                        display = true,
                        render,
                      } = makerParseFunctionValue(_tab, { ...actions, loading }, ['onClick']);
                      if (!display) return null;
                      if (!!render) return render;
                      return (
                        <Grid key={i} item xs={12}>
                          <AccordionWrapper
                            square={true}
                            padding={noPadding ? 0 : padding}
                            disabledBackground={disabledBackground}
                            expanded={this.getActiveTab() === i}
                          >
                            <Grid
                              container
                              onFocus={() => {
                                if (currentTab !== i) {
                                  this.setState({ currentTab: i });
                                  this.patchQueryParams({ tab: i }, true);
                                }
                              }}
                            >
                              {cards.map((card, j) => {
                                return (
                                  <Grid key={j} item xs={card?.xs || 12} md={card?.md || 12}>
                                    {this.renderCard(card, { ...actions, loading }, j)}
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </AccordionWrapper>
                        </Grid>
                      );
                    })}
                  <Grid item xs={12}>
                    {this.renderOthers(initialValues, { ...actions, loading })}
                  </Grid>
                  <Grid item xs={12}>
                    {!disableToolBar && this.renderFormToolBar(initialValues, { ...actions, loading })}
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Formik>
      </>
    );
  }

  renderTaber({ loading, ...actions }) {
    const { tabs: _tabs } = this.state,
      tabs = _tabs
        .map((tab) => ({
          ...tab,
          ...makerParseFunctionValue({ name: tab.name, display: tab.display }, { ...actions, loading }, ['onClick']),
        }))
        .filter(({ name, display = true }) => !!name && display);
    if (!tabs || tabs.length === 0) return null;
    return (
      <Taber
        tabs={tabs}
        currentTab={this.getActiveTab()}
        onTabChange={(currentTab) => {
          this.setState({
            currentTab,
          });
          this.patchQueryParams({ tab: currentTab }, true);
        }}
      />
    );
  }

  allowSubmit({ values }) {
    const {
      gql: { submit },
      editMode,
    } = this.state;
    if (!submit) return false;
    if (!!editMode && values?.__disabled) return false;
    return true;
  }
  getConfirmText({ values }) {
    return undefined;
  }

  allowRemove = () => {
    return true;
  };

  renderFormToolBar(initialValues, actions) {
    const {
      gql: { remove },
      id,
      editMode,
    } = this.state;
    const { loading, isSubmitting, setFieldValue, values } = actions || {};
    return (
      <FormToolbar
        extra={this.renderExtraButtons(actions)}
        disabled={loading || isSubmitting}
        onSave={null}
        loading={isSubmitting}
        confirmText={this.getConfirmText(actions)}
        submitBtnType={this.allowSubmit.bind(this)(actions) ? 'submit' : undefined}
        onCancel={this.onCancelClick?.bind(this)}
        updatedAt={initialValues.updatedAt}
        cancelText={translate.back}
        onEditClick={
          !!editMode && values?.__disabled
            ? () => {
                setFieldValue('__disabled', false);
              }
            : undefined
        }
        onRemoveClick={!!id && !!remove && this.allowRemove(actions) && (() => this.onRemoveClick({ ...actions }))}
      />
    );
  }
}
FormPageMaker.propTypes = {
  className: PropTypes.string,
};
FormPageMaker.defaultProps = {
  // className: 'mb-5',
};

export class StateParamFormPageMaker extends FormPageMaker {
  getQueryParams = () => {
    const { queryParams } = this.state;
    return queryParams ?? {};
  };
  getQueryParam = (key) => {
    const { queryParams } = this.state;
    return queryParams?.[key];
  };
  patchQueryParams = (nextParams) => {
    const { queryParams } = this.state;

    if (typeof nextParams === 'function') {
      nextParams = nextParams(queryParams);
    } else {
      nextParams = { ...queryParams, ...nextParams };
    }

    this.setState({
      queryParams: nextParams,
    });
    sleep(0).then(() => {
      this.setState(this.getQueryState());
    });
  };
}
