import React from 'react';
import FormPageMaker from '../../components/FormPageMaker';
import { translate } from '../../shared/translate';
import { GET_QUERY, POST_QUERY } from './query';
import { client } from '../../shared/apollo';
import { Card, Grid, InputAdornment } from '@material-ui/core';
import { convertMetaArray, convertMetaObject, isProduction, parseAddress, toInputMedia } from '../../shared';
import { Skeleton } from '@material-ui/lab';
import SelectComboWarehouse from '../../components/SelectCombo/SelectComboWarehouse';
import codes from 'country-calling-code';
import ct from 'countries-and-timezones';
import { CompanyShopType } from '../../shared/enum';
import FormCard from '../../components/FormPageMaker/Cards/FormCard';
import { checkPermissionV2 } from '../../components/PermissionMask';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    id: localStorage.getItem('shopId'),
    gql: {
      prepare: GET_QUERY,
      submit: POST_QUERY,
    },
    tabs: [
      {
        name: translate.merchant_setting,
        cards: [
          {
            fields: [
              {
                render: (actions) => (
                  <Card>
                    <FormCard
                      name={translate.merchant_info}
                      actions={actions}
                      fields={[
                        {
                          id: '#' + translate.shop_name,
                          label: translate.shop_name,
                          type: 'text',
                          name: 'name',
                          required: true,
                          description: translate.shop_name_remark,
                          md: 6,
                        },
                        {
                          id: '#' + translate.shop_type,
                          label: translate.shop_type,
                          type: 'select',
                          name: 'type',
                          options: CompanyShopType,
                          required: true,
                          md: 6,
                        },
                        {
                          id: '#' + translate.bank_display_name,
                          label: translate.bank_display_name,
                          description: translate.bank_display_name_remark,
                          type: 'text',
                          disabled: true,
                          md: 6,
                        },
                        {
                          id: '#' + translate.store_language,
                          label: translate.store_language,
                          description: translate.store_language_remark,
                          type: 'select',
                          name: 'locale',
                          options: [
                            {
                              label: '繁中',
                              value: 'zh-HK',
                            },
                            {
                              label: '简中',
                              value: 'zh-CN',
                            },
                            {
                              label: 'English',
                              value: 'en-US',
                            },
                          ],
                          md: 6,
                        },
                        {
                          id: '#' + translate.custom_domain,
                          label: translate.custom_domain,
                          type: 'text',
                          name: 'customDomain',
                          md: 6,
                          inputProps: {
                            minLength: 5,
                            maxLength: 32,
                            pattern: '^[a-zA-Z0-9\\-_.]{5,32}$',
                          },
                          placeholder: ({ values }) => values.hostname,
                          InputProps: {
                            startAdornment: <InputAdornment position={'start'}>https://</InputAdornment>,
                          },
                          description: translate.custom_domain_remark,
                        },
                        {
                          id: '#' + translate.sms_display_name,
                          label: translate.sms_display_name,
                          description: translate.sms_display_name_remark,
                          type: 'text',
                          disabled: true,
                          md: 6,
                        },
                        {
                          id: '#' + translate.currency,
                          label: translate.currency,
                          description: translate.not_modifiable_after_setup || '設定後不可更改',
                          type: 'select',
                          name: 'currency',
                          placeholder: translate.hkd,
                          disabled: ({ values }) => (isProduction() ? true : !!this.initialValues?.currency),
                          options: [
                            {
                              label: translate.hkd,
                              value: 'HKD',
                            },
                            {
                              label: translate.mop,
                              value: 'MOP',
                            },
                            {
                              label: translate.cny,
                              value: 'CNY',
                            },
                            {
                              label: translate.twd,
                              value: 'TWD',
                            },
                          ],
                          md: 3,
                        },
                        {
                          id: '#' + translate.timezone,
                          label: translate.timezone,
                          type: 'select',
                          name: 'timezone',
                          options: codes
                            .map(({ isoCode2, isoCode3 }) => {
                              const tz = ct.getTimezonesForCountry(isoCode2) || [];
                              return !!tz[0]
                                ? {
                                    label: `${translate.countries[isoCode3]} ${tz[0].utcOffsetStr}`,
                                    value: tz[0].name,
                                  }
                                : undefined;
                            })
                            .filter((_) => _),
                          required: true,
                          md: 3,
                        },
                        {
                          id: '#' + translate.sales_representative,
                          label: translate.sales_representative,
                          type: 'text',
                          name: 'metadata.salesRepresentative',
                          md: 12,
                        },
                        !!checkPermissionV2('viewMerchantRemark') && {
                          id: '#' + translate.remarks,
                          label: translate.remark || '備註',
                          type: 'text',
                          name: 'remarks',
                          md: 12,
                        },
                      ]}
                    />
                  </Card>
                ),
              },
              {
                label: translate.merchant_address,
                display: false,
              },
              {
                md: 6,
                render: (actions) => (
                  <Card id={'#' + translate.merchant_address}>
                    <FormCard
                      name={translate.merchant_address}
                      actions={actions}
                      fields={[
                        {
                          label: translate.contact_person,
                          required: true,
                          md: 12,
                          type: 'text',
                          name: 'address.person',
                        },
                        {
                          id: '#' + translate.contact_phone_no,
                          label: translate.contact_phone_no,
                          required: true,
                          md: 12,
                          type: 'mobile',
                          name: 'address.tel',
                        },
                        {
                          label: translate.email,
                          required: true,
                          md: 12,
                          type: 'email',
                          name: 'address.email',
                        },
                        {
                          label: translate.address,
                          required: true,
                          md: 12,
                          type: 'lines',
                          name: 'address.lines',
                        },
                        {
                          label: translate.country,
                          required: true,
                          md: 12,
                          type: 'country',
                          name: 'address.country',
                        },
                      ]}
                    />
                  </Card>
                ),
              },
              {
                md: 6,
                render: (actions) => (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Card>
                        <FormCard
                          name={translate.warehouse_information || '倉庫資訊'}
                          actions={actions}
                          fields={[
                            {
                              id: '#' + translate.stock_warehouse,
                              label: translate.stock_warehouse,
                              render: ({ loading, values, isSubmitting, setFieldValue }) =>
                                loading ? (
                                  <Skeleton height={30} />
                                ) : (
                                  <SelectComboWarehouse
                                    value={values.stockWarehouse}
                                    required={true}
                                    disabled={isSubmitting}
                                    onChange={(v) => setFieldValue('stockWarehouse', v)}
                                  />
                                ),
                              md: 12,
                              xs: 6,
                            },
                            {
                              id: '#' + translate.reserve_warehouse,
                              label: translate.reserve_warehouse,
                              render: ({ loading, values, isSubmitting, setFieldValue }) =>
                                loading ? (
                                  <Skeleton height={30} />
                                ) : (
                                  <SelectComboWarehouse
                                    value={values.reservationWarehouse}
                                    // required={true}
                                    disabled={true || isSubmitting}
                                    onChange={(v) => setFieldValue('reservationWarehouse', v)}
                                  />
                                ),
                              md: 12,
                              xs: 6,
                            },
                            {
                              id: '#' + translate.transfer_warehouse,
                              label: translate.transfer_warehouse,
                              render: ({ loading, values, isSubmitting, setFieldValue }) =>
                                loading ? (
                                  <Skeleton height={30} />
                                ) : (
                                  <SelectComboWarehouse
                                    value={values.transferWarehouse}
                                    // required={true}
                                    disabled={isSubmitting}
                                    onChange={(v) => setFieldValue('transferWarehouse', v)}
                                  />
                                ),
                              md: 12,
                              xs: 6,
                            },
                            {
                              id: '#' + translate.return_warehouse,
                              label: translate.return_warehouse,
                              render: ({ loading, values, isSubmitting, setFieldValue }) =>
                                loading ? (
                                  <Skeleton height={30} />
                                ) : (
                                  <SelectComboWarehouse
                                    value={values.returnWarehouse}
                                    // required={true}
                                    disabled={isSubmitting}
                                    onChange={(v) => setFieldValue('returnWarehouse', v)}
                                  />
                                ),
                              md: 12,
                              xs: 6,
                            },
                          ]}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <FormCard
                          name={translate.logo || '商標'}
                          actions={actions}
                          fields={[
                            {
                              id: '#' + translate.shop_logo,
                              label: translate.shop_logo,
                              type: 'media',
                              name: 'logoMedia',
                              text: translate.shop_logo_remark,
                              lg: 4,
                              md: 6,
                              xs: 12,
                            },
                            {
                              id: '#' + translate.rasterLogo,
                              label: translate.rasterLogo,
                              type: 'media',
                              name: 'rasterLogoMedia',
                              text: translate.rasterLogo_remark,
                              lg: 4,
                              md: 6,
                              xs: 12,
                            },
                            {
                              id: '#' + translate.ico,
                              label: translate.ico,
                              type: 'media',
                              name: 'icoMedia',
                              text: translate.ico_remark,
                              lg: 4,
                              md: 6,
                              xs: 12,
                            },
                          ]}
                        />
                      </Card>
                    </Grid>
                  </Grid>
                ),
              },
            ],
          },
        ],
      },
      {
        name: translate.referenceNo_setting,
        onClick: () => {
          this.props.history.push('/merchant/reference_no_setting');
        },
      },
    ],
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    const { data: { companyShopSet } = {} } = await client.mutate({
        mutation: submit,
        variables: {
          id,
          input: {
            address: values.address,
            customDomain: values.customDomain,
            name: values.name,
            type: values.type,
            currency: this.initialValues?.currency === values?.currency ? undefined : values?.currency,
            icoMedia: toInputMedia(values.icoMedia),
            logoMedia: toInputMedia(values.logoMedia),
            rasterLogoMedia: toInputMedia(values.rasterLogoMedia),
            locale: values.locale,
            timezone: values.timezone,
            stockWarehouseId: (values.stockWarehouse || {}).id,
            transferWarehouseId: (values.transferWarehouse || {}).id,
            returnWarehouseId: values?.returnWarehouse?.id,
            remarks: values.remarks,
            metadata: convertMetaArray(values.metadata),
          },
        },
      }),
      { stockWarehouse, type } = companyShopSet || {};
    localStorage.setItem('stockWarehouseId', (stockWarehouse || {}).id);
    localStorage.setItem('shopType', type);
    return false;
  };

  getInitialData({ node, me }) {
    const {
      hostname,
      customDomain,
      name,
      locale,
      currency,
      icoMedia,
      logoMedia,
      type,
      timezone,
      rasterLogoMedia,
      updatedAt,
      address,
      stockWarehouse,
      reservationWarehouse,
      transferWarehouse,
      returnWarehouse,
      remarks,
      metadata,
    } = node || {};

    return {
      address: parseAddress(address),
      hostname,
      customDomain,
      name,
      locale,
      currency,
      icoMedia,
      logoMedia,
      type,
      timezone,
      rasterLogoMedia,
      updatedAt,
      stockWarehouse,
      reservationWarehouse,
      transferWarehouse,
      returnWarehouse,
      remarks,
      metadata: convertMetaObject(metadata),
    };
  }
}
