import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import Copyright from '../../components/Copyright';
import { queryString2Object } from '../../components/QueryParamsWrapper';
import { useLocalStorage } from 'react-use';
import { client } from '../../shared/apollo';
import { GET_LOGIN_DATA } from './query';
import { parseConnection } from '../../shared';
import OmniweLoading from '../../components/OmniweLoading';
import * as Sentry from '@sentry/browser';
import moment from 'moment';
import useRetryEffect from '../../components/useRetryEffect';

const { REACT_APP_MAIN_SITE_URL } = process.env;

export default ({ history, location: { search = '' } = {} }) => {
  const { token: queryToken } = queryString2Object(search);
  const [localToken, setLocalToken] = useLocalStorage('token', '', { raw: true });
  const { error } = useRetryEffect(async () => {
    const token = queryToken || localToken;
    if (!token) throw new Error('no_token');
    await setLocalToken(token);
    await new Promise((resolve) => setTimeout(resolve, 100));
    await prepareLoginData();
  });

  useEffect(() => {
    if (error) {
      window.location.href = `${REACT_APP_MAIN_SITE_URL}/login?redirectUrl=${window.location.origin}/login`;
    }
  }, [error]);

  return (
    <Container
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ flex: 1 }} />
      <OmniweLoading loading={true} />
      <div style={{ flex: 1 }} />
      <Copyright />
    </Container>
  );

  async function prepareLoginData() {
    console.log('localToken', localToken);

    const endAt = moment().add(2, 'minutes').toISOString();
    let shops = { nodes: [], totalCount: 0 };
    do {
      if (moment().isAfter(endAt)) {
        Sentry.captureException(new Error('Error: No available shops'));
        throw new Error('Error: No available shops');
      }

      const {
        data: { me = {} },
      } = await client.query({
        query: GET_LOGIN_DATA,
        fetchPolicy: 'no-cache',
      });
      Sentry.setUser({
        id: me.id,
        email: me.email,
        username: me.name,
      });

      shops = parseConnection(me.shops);
    } while (shops.totalCount === 0);

    const {
      id: shopId,
      type: shopType,
      company: { id: companyId } = {},
      stockWarehouse: { id: stockWarehouseId } = {},
      currency = 'HKD',
    } = shops.nodes[0] || {};

    if (!!shopId) localStorage.setItem('shopId', shopId);
    if (!!shopType) localStorage.setItem('shopType', shopType);
    if (!!companyId) localStorage.setItem('companyId', companyId);
    if (!!stockWarehouseId) localStorage.setItem('stockWarehouseId', stockWarehouseId);
    localStorage.setItem('currency', currency || 'HKD');

    if (shops.totalCount === 1) {
      history.replace(localStorage.getItem('latestPathname') || '/');
    } else {
      history.replace(localStorage.getItem('latestPathname') || '/shops');
    }
  }
};
