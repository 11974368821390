import { translate } from 'shared/translate';
import thousandsCounter from 'thousands-counter';
import jwt_decode from 'jwt-decode';
import csvStringify from 'csv-stringify';
import moment from 'moment';
import codes from 'country-calling-code';
import { client } from './apollo';
import hash from 'object-hash';
import _ from 'lodash';
import ActivityIndicator from '../components/ActivityIndicator';
import toast from './toast';
import errorParser from './errorParser';
import { GET_QUERY as GET_CREDENTIALS_QUERY } from '../routes/list_Credentials/query';
import pLimit from 'p-limit';
import Fuse from 'fuse.js';

export const parseMedia = (media, optimized = true) => {
  if (optimized) return media?.optimizedSrc || media?.src || '';
  return media?.src || media?.optimizedSrc || '';
};
export const parseSecondDuration = (seconds = 0) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return {
    hours,
    minutes,
    seconds: remainingSeconds,
  };
};
export function unifiedReferenceNo(referenceNo, useFull) {
  if (useFull) return referenceNo;
  return `${referenceNo || ''}`.slice(-3).padStart(3, '0');
}
export const colorCodeToRgb = (colorCode) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorCode);
  if (!!result) {
    let r = parseInt(result[1], 16);
    let g = parseInt(result[2], 16);
    let b = parseInt(result[3], 16);
    return r + ', ' + g + ', ' + b; //return 23,14,45 -> reformat if needed
  }
  return null;
};

export function mergeConnections(...connections) {
  return connections.reduce((reducer, connection) => {
    if (connection) {
      const { nodes, nextCursor, totalCount } = parseConnection(connection);
      reducer.nodes.push(...nodes);
      reducer.totalCount = Math.max(totalCount, reducer.nodes.length);
      reducer.nextCursor = nextCursor;
    }
    return reducer;
  }, parseConnection([]));
}

export const translateNonMemberLabel = (arr) => {
  return arr.map(({ name, value }) => {
    if (name === 'N/A') return { name: translate.nonmember, value };
    return { name, value };
  });
};

export async function getImageSize(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      resolve({ width: 0, height: 0 });
    };
    img.src = src;
  });
}

export const colorCodeWithOpacity = (color, opacity) => {
  return `rgba(${colorCodeToRgb(color)}, ${opacity})`;
};
export const omniweEmailTest = (email) => /@\S+\.omniwe\.site$/.test(email);
export const setAttributes = (data = {}, fields = []) => {
  return Object.keys(data).reduce((prev, key) => {
    if (fields.indexOf(key) >= 0) {
      if (!data[key] || data[key] === '') {
        return prev;
      }

      return [
        ...prev,
        {
          key,
          value: data[key],
        },
      ];
    } else {
      return prev;
    }
  }, []);
};
export const toInputMedia = (media) => {
  if (!media) return null;
  return {
    id: media.id,
    src: media.src,
    alt: media.alt,
  };
};

export const infinityFetch = async ({
  query,
  variables = {},
  getConnection = (data) => ({
    nodes: [],
    totalCount: 0,
    nextCursor: null,
  }),
  onProgress = (_) => _,
  concurrency = 3,
  total = 0,
  ...options
}) => {
  const limits = variables?.limits ?? 20;
  const fetchThrottle = pLimit(concurrency);
  onProgress(0, total);

  let completed = false;
  let connection = await fetchData();
  let successCount = connection.nodes.length;

  onProgress(successCount, total || connection.totalCount || 0);

  const totalNumbersOfPage = Math.ceil(connection.totalCount / limits);
  if (totalNumbersOfPage === 1) completed = true;

  const connections = await Promise.all(
    Array(Math.max(totalNumbersOfPage - 1, 0))
      .fill(undefined)
      .map((__, i) =>
        fetchThrottle(async () => {
          const cursor = (i + 1) * limits;
          const connection = await fetchData(cursor);
          successCount += connection.nodes.length;
          onProgress(successCount, total || connection.totalCount || 0);
          if (successCount === connection.totalCount) completed = true;
          return connection;
        }),
      ),
  );

  onProgress(total || connection.totalCount || 0, total || connection.totalCount || 0);
  return mergeConnections(connection, ...connections);

  async function fetchData(cursor) {
    return retryWrapper(async () => {
      const { data } = await client.query({
        query,
        variables: {
          ...variables,
          limits,
          cursor,
        },
        ...options,
      });
      return parseConnection(getConnection(data));
    });
  }
};

export const retryWrapper = async (fn, retryInterval = 100, retryCount = 5) => {
  let i = retryCount;
  do {
    try {
      return await fn(() => (i = 0));
    } catch (e) {
      i--;
      await new Promise((resolve) => setTimeout(resolve, retryInterval));
      if (i <= 0) throw e;
    }
  } while (i > 0);
};

export function parseConnection(obj) {
  if (Array.isArray(obj)) return { nodes: obj, nextCursor: null, totalCount: obj.length };
  const { nodes, nextCursor, totalCount } = obj || {};
  return { nodes: nodes || [], nextCursor, totalCount: totalCount || 0 };
}

export const listReorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export function parseAddress(_address, fallbackToUndefined = false) {
  const address = _address || {};
  const obj = _.pickBy(
    {
      id: address.id || undefined,
      name: address.name || undefined,
      person: address.person || undefined,
      tel: address.tel || undefined,
      email: address.email || undefined,
      lines: (address.lines || []).filter((_) => _),
      city: address.city || undefined,
      country: address.country || undefined,
      district: address.district || undefined,
      isDefault: address.isDefault || undefined,
    },
    _.identity,
  );

  if (fallbackToUndefined) {
    if (Object.values(obj).length === 0) return undefined;
    if (Object.values(obj).length === 1 && !!obj?.lines) {
      if (!obj?.lines?.filter(Boolean).length) return undefined;
    }
  }

  return obj;
}

export function parseCustomerName(customer) {
  if (customer?.name) return customer?.name;
  const { name, firstName, first_name, lastName, last_name, familyName, family_name, givenName, given_name } =
    convertMetaObject(customer?.metadata ?? []);
  return (
    name ??
    [givenName ?? given_name, firstName ?? first_name, familyName ?? family_name, lastName ?? last_name]
      .filter((_) => _)
      .join(' ')
  );
}

export function escapeRegExp(string) {
  return (string || '').replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function exportCSV(title = '', cols = [], data = []) {
  const length = cols.length;
  const rowTitle = new Array(length).fill('');
  rowTitle[0] = title || '';

  var input = [rowTitle, cols];
  for (const row of data) {
    input.push(row);
  }

  csvStringify(input, function (err, output) {
    const date = moment().format('YYYY-MM-DD');
    const filename = `${title}${!!title.length && '_'}${date}`;
    const csvInfo = `data:text/csv;charset=utf-8,`;
    const contents = encodeURI(`${csvInfo}${output}`);

    console.log('output', output);

    var link = document.createElement('a');
    link.download = filename;
    link.href = contents;
    link.click();
  });
}

export function JSONParseSafely(json, fallback = {}) {
  try {
    if (json === undefined) return fallback;
    if (typeof json === 'string') return JSON.parse(json);
  } catch (e) {}
  return fallback;
}

export function removeId(input) {
  if (!input) return input;

  if (Array.isArray(input)) {
    for (const value of input) removeId(value);
  } else if (typeof input === 'object') {
    delete input.id;
    for (const value of Object.values(input)) removeId(value);
  }
  return input;
}
export function removeTypename(input) {
  if (!input) return input;

  if (Array.isArray(input)) {
    for (const value of input) removeTypename(value);
  } else if (typeof input === 'object') {
    delete input.__typename;
    for (const value of Object.values(input)) removeTypename(value);
  }
  return input;
}

export function cloneObject(input) {
  return JSONParseSafely(JSON.stringify(input));
}

export function PriceFormat(value, currency = 'USD') {
  const _value = value - 0 || 0;
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(_value);
}

export function NumberFormat(value) {
  const _value = value - 0 || 0;
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: Math.min(checkNumberOfDigits(_value), 2),
  }).format(_value);
}

export function NumberBriefFormat(value) {
  const _value = (value - 0 || 0).toFixed(2) - 0 || 0;
  return thousandsCounter(_value, { digits: 1, uppercase: false });
}

export function checkNumberOfDigits(value) {
  const number = value - 0 || 0;
  const tmp = `${number}`.split('.');

  if (tmp.length <= 1) return 0;
  const fragment = tmp[tmp.length - 1];
  return fragment.length;
}

export const capitalize = (s) => {
  if (!s || typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function getRandomString(
  length = 16,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
) {
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getCommonColumns({ createdAt = {}, updatedAt = {} } = {}) {
  return [
    {
      title: translate.created_at,
      width: 180,
      align: 'right',
      fieldName: 'createdAt',
      type: 'datetime',
      filter: 'createdAt',
      sortBy: 'createdAt',
      ...createdAt,
    },
    {
      title: translate.updated_at,
      width: 180,
      align: 'right',
      fieldName: 'updatedAt',
      type: 'datetime',
      filter: 'updatedAt',
      sortBy: 'updatedAt',
      ...updatedAt,
    },
  ];
}

export async function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function flattenItems(items = [], key = 'children', _parent) {
  return items.reduce((reducer, item) => {
    item._parent = _parent;
    return reducer.concat(item, ...flattenItems(item[key] || [], key, item));
  }, []);
}

export function extractImageFromHTML(html) {
  const [img] = (html || '').match(/src=\\?"(https?:\/\/.*?(?:png|jpg|jpeg).*?)\\?"/i) || [];
  return (img || '').replace(/src=\\?"(.*?)\\?"/i, '$1');
}

export function extractTextFromHTML(html) {
  const temporalDivElement = document.createElement('div');
  temporalDivElement.innerHTML = html;
  return temporalDivElement.textContent || temporalDivElement.innerText || '';
}

export function isUUID(string = '') {
  return /^[0-9a-fA-F]{6}-[0-9a-fA-F]{4}-[0-9a-fA-F]{3}-[0-9a-fA-F]{4}-[0-9a-fA-F]{11}$/.test(string);
}

export function isAuth0ID(string = '') {
  return /^auth0\|/.test(string);
}

export const getTokenData = (token = localStorage.getItem('token')) => {
  try {
    return jwt_decode(token);
  } catch (e) {
    return {};
  }
};

export function stringToColor(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export const parseAgoTime = (createdAt) => {
  const timeDifference = Math.max(moment().unix() - moment(createdAt).unix(), 0);

  let timeStr;
  const minutes = timeDifference / 60;
  if (minutes < 1) timeStr = translate.just_a_moment;
  else if (minutes < 60) timeStr = translate.formatString(translate.n_minute_ago, `${~~minutes}`);
  else if (minutes < 60 * 24) timeStr = translate.formatString(translate.n_hour_ago, `${~~(minutes / 60)}`);
  else timeStr = translate.formatString(translate.n_day_ago, `${~~(minutes / (60 * 24))}`);

  return timeStr;
};

export const getCurrentRoles = () => {
  const companyId = localStorage.getItem('companyId'),
    shopId = localStorage.getItem('shopId');
  const data = getTokenData();
  const permissions = data[Object.keys(data).find((key) => /permissions/.test(key))] || {};
  return permissions['*/*'] || permissions[`${companyId}/*`] || permissions[`${companyId}/${shopId}`] || [];
};

export const isJWTValid = (jwt = '') => {
  try {
    const decoded = jwt_decode(jwt);
    let currentDate = new Date();
    return decoded.exp * 1000 >= currentDate.getTime();
  } catch (e) {
    return false;
  }
};

export function addValue2Metadata(key, value, metadata) {
  if (!_.isNil(key) && !_.isNil(value)) {
    const existIndex = (metadata ?? []).findIndex((meta) => meta?.key === key);
    if (!!~existIndex) {
      return metadata.map((meta, i) =>
        i === existIndex
          ? {
              key,
              value,
            }
          : meta,
      );
    } else
      return (metadata ?? []).concat({
        key,
        value,
      });
  }
  return metadata ?? [];
}

export function metadataDeleteKey(key, metadata) {
  return (metadata ?? []).filter((meta) => meta?.key !== key);
}

export function findValueFromMetadata(key, metadata) {
  return (metadata ?? []).find((meta) => meta?.key === key)?.value;
}

export function convertMetaObject(array = []) {
  return (array || []).reduce(
    (reducer, { key, value } = {}) => ({
      ...reducer,
      [key]: convertString(value),
    }),
    {},
  );

  function convertString(str) {
    if (str === 'true') return true;
    else if (str === 'false') return false;
    else if (/^[0-9\.]+$/.test(str) && !isNaN(str) && str?.length <= 13) return parseFloat(str);
    return str;
  }
}

export function convertMetaArray(object = {}) {
  return Object.keys(object)
    .map((key) => ({ key, value: `${object[key]}` }))
    .filter((meta) => !!meta.key && meta.value !== 'undefined' && meta.value !== 'null');
}

export function convertObject2Array(object = {}) {
  return convertMetaArray(object);
}

export function parseProductImages(product, optimized = true) {
  const images = (product || {}).images;
  const medias = (product || {}).medias;

  if (!!medias) return medias.map(({ src, optimizedSrc }) => (optimized ? optimizedSrc || src : src || optimizedSrc));
  else if (!!images) return images;
  return [];
}

export const stringToColour = (str) => {
  let hash = 0;
  str.split('').forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, '0');
  }
  return colour;
};

export const dayRangeHeaderFormat = ({ start, end }, culture, local) => {
  const weekDate = `${local.format(start, 'MMM')} ${local.format(start, 'DD')} – ${local.format(end, 'DD YYYY')}`;
  // console.log('month: ', translate[local.format(start, 'MMM')]);
  return weekDate;
};

export function isDevelopment() {
  return !isStaging() && !isProduction();
}

export function isStaging() {
  return /^https:\/\/gql\.omniwe\.org/.test(process?.env?.REACT_APP_API_URL);
}

export function fuzzyFilterOptions(options = [], { inputValue, getOptionLabel, threshold = 0.5 }) {
  if (!inputValue) return options;

  try {
    inputValue = inputValue.replace(/[+\[\]{}()^$!*]/g, '');

    const _getOptionLabel = getOptionLabel ?? ((opt) => opt?.label ?? '');
    {
      const regex1 = new RegExp(escapeRegExp(inputValue), 'gi');
      const results = options.filter((option) => {
        const label = _getOptionLabel(option);
        regex1.lastIndex = 0;
        return regex1.test(label);
      });
      if (results.length > 0) return results;
    }

    {
      const fuse = new Fuse(
        options.map((opt) => _getOptionLabel(opt)),
        {
          includeScore: true,
          threshold,
        },
      );
      const results = fuse.search(inputValue ?? '');
      const filteredResults = results.filter((result) => result.score <= threshold);
      return options.filter((opt) => {
        const label = _getOptionLabel(opt);
        return filteredResults.find((result) => result.item === label);
      });
    }
  } catch {
    return options;
  }
}

export function isProduction() {
  return /^https:\/\/gql\.omniwe\.com/.test(process?.env?.REACT_APP_API_URL);
}

export async function getCredential(platform) {
  const { data: { node } = {} } = await client.query({
    query: GET_CREDENTIALS_QUERY,
    fetchPolicy: 'no-cache',
    variables: {
      id: localStorage.getItem('shopId'),
      filterV2: {
        platforms: [platform],
      },
    },
  });
  return parseConnection(node.credentials).nodes.find((cred) => cred.active);
}

export function removePhoneNumberRegion(_input) {
  const input = _input || '';
  const { countryCodes } =
    codes.find(({ countryCodes }) => {
      const countryCode = (countryCodes || [])[0] || '';
      return input.indexOf(`+${countryCode}`) === 0;
    }) || {};
  const countryCode = (countryCodes || [])[0];
  if (!!countryCode)
    return {
      region: `+${countryCode}`,
      clear: input.replace(`+${countryCode}`, ''),
    };

  return { clear: input };
}

const rapidRepeatCallKeys = [];
export function preventRapidRepeatCall(timeout = 250, key = 'default-key') {
  if (rapidRepeatCallKeys.includes(key)) return false;
  rapidRepeatCallKeys.push(key);
  setTimeout(() => {
    rapidRepeatCallKeys.splice(rapidRepeatCallKeys.indexOf(key), 1);
  }, timeout);
  return true;
}

export function makerParseFunctionValue(props, functionParam, excludes = []) {
  const obj = {};
  Object.keys(props).forEach((key) => {
    if (!excludes.includes(key)) obj[key] = getValue(props[key]);
    else obj[key] = props[key];
  });
  return obj;
  function getValue(input) {
    if (typeof input === 'function') {
      try {
        return input(functionParam);
      } catch (e) {}
    }
    return input;
  }
}

export function obj2Hash(obj) {
  return hash(obj || {});
}

function splitChineseEnglish(str) {
  return (
    `${str || ''}`
      .match(/([\u4e00-\u9fff\uFF00-\uFFEF《》。、，・•「 」\n]+)|([ÁA-Za-z0-9\x20-\x7E·®é]+)/g)
      ?.map((str) => {
        if (/^[\u4e00-\u9fff\uFF00-\uFFEF《》。、，・•「 」\n]+$/.test(str)) return { str, lang: 'c' };
        return { str, lang: 'e' };
      }) ?? []
  );
}

export function parseOSSQuery(q, fields = []) {
  if (!q) return undefined;
  if (fields.length === 0) return q;
  const keywords = _.uniq(
    [
      ...q
        .split(/[\s\-~!@#$%^&*()_+`=\\|[{\]};:'",<.>/? ]/gim)
        .filter(Boolean)
        .map((str) =>
          splitChineseEnglish(str).map(({ str, lang }) => {
            if (lang === 'c') return `"${str}"`;
            return `*${str}*`;
          }),
        )
        .flat(),
    ].filter(Boolean),
  );

  return `${fields
    .map((fi) => {
      return (
        '( ' +
        keywords
          .map((kw) => {
            return `${fi}:${kw}`;
          })
          .join(' AND ') +
        ' )'
      );
    })
    .join(' OR ')}`;
}

export async function actionWrapper(
  action = (_) => _,
  { onError = (_) => _, messages: { loading, success } = {} } = {},
) {
  try {
    ActivityIndicator.show(loading);
    await action();
    !!success && toast.success(success);
  } catch (e) {
    toast.error(errorParser(e));
    await onError(e);
  } finally {
    ActivityIndicator.hide();
  }
}

export function getCurrency() {
  return localStorage.getItem('currency') || 'HKD';
}
