import React, { useState } from 'react';
import InputField from 'components/FormPageMaker/Fields/InputField';

import { gql } from '@apollo/client';
import { IconButton, InputAdornment } from '@material-ui/core';
import { Icon } from '../../IconRender';

export const UPLOAD_IMAGE_QUERY = gql(`
query ($inputUrl: String!) {
  uploadImageUrl(inputUrl:$inputUrl)
}
`);

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}

export default function ImageLinkField({ value, disabled, placeholder = '', onChange = () => {}, ...props }) {
  const [pastedLink, setPastedLink] = useState();
  return (
    <>
      <InputField
        key={pastedLink}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        placeholder={placeholder}
        onPaste={async (e) => {
          const url = e.clipboardData.getData('Text');
          setPastedLink(url);
          onChange(url);
        }}
        InputProps={{
          endAdornment: !!value && (
            <InputAdornment position={'end'}>
              <IconButton
                size={'small'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open(value, '_blank');
                }}
              >
                <Icon icon={'faExternalLink'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    </>
  );
}
