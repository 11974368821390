import { gql } from '@apollo/client';

export const GET_QUERY = gql`
  query ($shopId: ID!, $id: ID!) {
    shop: node(id: $shopId) {
      id
      ... on CompanyShop {
        logoMedia {
          src
          optimizedSrc(width: 256, height: 256)
        }
      }
    }
    node(id: $id) {
      id
      ... on CompanyStockTransfer {
        createdAt
        updatedAt
        status
        remark
        inboundWarehouse {
          id
          name
        }
        outboundWarehouse {
          id
          name
        }
        itemsV2 @client
      }
    }
  }
`;
