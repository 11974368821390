import React from 'react';
import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { GET_USER_COUPONS_QUERY } from './query';
import { translate } from '../../../shared/translate';
import { useHistory, useLocation } from 'react-router-dom';
import { UserCouponStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';
import CreateUserCouponButton from './CreateUserCouponButton';
import MenuImport from '../../list_Offer/Coupons/MenuImport/index';

export default ({ values: { id, userCouponsTotal } = {} }) => {
  const history = useHistory(),
    location = useLocation();
  return !!id && <RecordCard id={id} userCouponsTotal={userCouponsTotal} history={history} location={location} />;
};

class RecordCard extends ConnectionPageMaker {
  state = {
    ...this.state,
    menus: [MenuImport],
    gql: {
      get: GET_USER_COUPONS_QUERY,
    },
    fields: [
      {
        title: translate.date,
        align: 'left',
        width: 180,
        fieldName: 'createdAt',
        type: 'datetime',
        filter: 'createdAt',
      },
      {
        title: translate.sub_coupon_id || '子優惠券編號',
        width: 150,
        fieldName: 'handle',
        type: 'text',
      },
      {
        title: translate.usage_status || '使用狀態',
        align: 'center',
        width: 60,
        fieldName: 'status',
        type: 'option',
        options: Object.keys(UserCouponStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={UserCouponStatus} />,
          value: key,
        })),
        filter: 'status',
      },
    ],
    limits: 10,
  };

  getData = ({ node } = {}) => {
    const { userCoupons } = node || {};
    return userCoupons;
  };

  getExtraFetchVariables() {
    const { values } = this.props;
    return { id: values?.id };
  }

  getExtraFetchVariables() {
    const { id } = this.props;
    return { id };
  }
  renderCreateButton({ totalCount }) {
    const { id } = this.props;
    return <CreateUserCouponButton id={id} userCouponsTotal={totalCount} onCompleted={this.refetch} />;
  }
  getSortBy() {
    const { sortBy } = this.state;
    return sortBy
      ? [sortBy]
      : [
          {
            field: 'handle',
            order: 'ASC',
          },
        ];
  }
}
