import ConnectionPageMaker from '../../../components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import MenuActivate from './MenuActivate';
import MenuDeactivate from './MenuDeactivate';
import MenuDelete from './MenuDelete';
import MenuExportRecord from './MenuExportRecord';
import { translate } from '../../../shared/translate';
import { itemCouponCodeField } from '../../item_Coupon/itemCouponCodeField';

export default class extends ConnectionPageMaker {
  type = translate.coupons;
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/coupons/new',
    getRowLink: ({ id, name }) => ({
      pathname: '/coupons/' + id,
      state: { title: name },
    }),
    fields: itemCouponCodeField.bind(this)(false),
    menus: [MenuExportRecord, MenuActivate, MenuDeactivate, MenuDelete],
    selectionMode: true,
    hasQSearch: true,
    qFields: ['name', 'handle', 'remark'],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('companyId') };
  }
  getData = ({ node } = {}) => {
    const { coupons } = node || {};
    return coupons;
  };
  renderWrapper(...args) {
    return this.renderContent(...args);
  }
}
