import React from 'react';
import { translate } from '../../shared/translate';
import { Grid } from '@material-ui/core';
import CheckboxField from '../../components/FormPageMaker/Fields/CheckboxField';
import { isProduction } from '../../shared';

const SalesChannelField = ({ disabled, value = [], onChange }) => {
  return (
    <Grid container spacing={2}>
      {!isProduction() && (
        <Grid item>
          <CheckboxField disabled={true} checked={true} text={translate.online_shop || '網店'} />
        </Grid>
      )}
      {!isProduction() && (
        <Grid item>
          <CheckboxField
            disabled={isProduction()}
            checked={value?.includes('POS')}
            onChange={(e) => {
              if (e.target.checked) {
                onChange([...value, 'POS']);
              } else {
                onChange(value.filter((v) => v !== 'POS'));
              }
            }}
            text={'POS'}
          />
        </Grid>
      )}
      <Grid item>
        <CheckboxField
          disabled={disabled}
          checked={value?.includes('CUTSOMERADO')}
          onChange={(e) => {
            if (e.target.checked) {
              onChange([...value, 'CUTSOMERADO']);
            } else {
              onChange(value.filter((v) => v !== 'CUTSOMERADO'));
            }
          }}
          text={translate.cutsomerado || 'QR Code下單'}
        />
      </Grid>
    </Grid>
  );
};

export default SalesChannelField;
