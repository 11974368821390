import React, { useEffect, useState } from 'react';
import { client } from 'shared/apollo';
import { toast } from 'shared/toast';
import errorParser from 'shared/errorParser';
import SwitchField from '../../../../components/FormPageMaker/Fields/SwitchField';
import { POST_QUERY } from '../../../item_ServiceLocation/query';

export default ({ id, active, onCompleted }) => {
  const [loading, setLoading] = useState(false);
  const shopId = localStorage.getItem('shopId');
  const [isActive, setIsActive] = useState(active);
  useEffect(() => {
    if (active !== isActive) setIsActive(active);
  }, [active]);

  const handleMutation = async () => {
    try {
      setIsActive(!active);
      setLoading(true);
      await client.mutate({
        mutation: POST_QUERY,
        variables: {
          id: id || undefined,
          input: {
            active: !active,
          },
        },
      });
      onCompleted();
    } catch (e) {
      handleMutationError(e);
      setIsActive(active);
    } finally {
      setLoading(false);
    }
  };

  const handleMutationError = (error) => {
    const errorMessage = errorParser(error);
    toast.error(errorMessage);
  };

  return <SwitchField checked={isActive} value={isActive} disabled={loading} onChange={handleMutation} />;
};
