import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import uuid from 'react-uuid';
import LSkeleton from '../../LSkeleton';
import Dnd from '../../DND';
import MagicMediaField from './MagicMediaField';

const MagicMediasField = ({ loading, disabled, value, onChange = (_) => _ }) => {
  const medias = value || [];
  const [tmp, setTmp] = useState({});

  useEffect(() => {
    if ((!!tmp.src && !/^data/.test(tmp.src)) || (!!tmp.optimizedSrc && !/^data/.test(tmp.optimizedSrc))) {
      onChange([...medias, { ...tmp, id: uuid() }]);
      setTmp({});
    }
  }, [tmp.src, tmp.optimizedSrc]);

  if (loading)
    return (
      <Grid container direction={'row'} spacing={2}>
        {Array(3)
          .fill(undefined)
          .map((__, i) => (
            <Grid item key={i}>
              <LSkeleton variant={'rect'} width={100} height={100} />
            </Grid>
          ))}
      </Grid>
    );

  return (
    <Dnd
      items={medias}
      renderItem={(media, index) => (
        <MagicMediaField
          value={media}
          onChange={(_media) => {
            if (_media)
              medias[index] = {
                ...media,
                ..._media,
              };
            else medias.splice(index, 1);
            onChange([...medias]);
          }}
        />
      )}
      onChange={onChange}
    >
      <MagicMediaField disabled={disabled} value={tmp} onChange={setTmp} hideRemoveButton />
    </Dnd>
  );
};

export default MagicMediasField;
