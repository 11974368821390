import { Grid, InputAdornment, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import InputField from '../../../components/FormPageMaker/Fields/InputField';
import SelectComboProduct from '../../../components/SelectCombo/SelectComboProduct';
import { translate } from '../../../shared/translate';
import TrashButton from '../../../components/TrashButton';
import { binColor } from '../../../theme';
import SwitchField from '../../../components/FormPageMaker/Fields/SwitchField';

const BundleProductItem = ({ disabledDelete, value, disabled, onChange }) => {
  const { product, price, hashtags } = value || {};
  const ref = useRef();

  return (
    <Grid container style={{ padding: 12, backgroundColor: '#eee', alignItems: 'center' }} spacing={1}>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1">
              {translate.product_name} <span style={{ color: binColor }}>{' *'}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} ref={ref}>
            <SelectComboProduct
              value={product}
              disabled={disabled}
              required
              onChange={(e) => {
                onChange({ ...value, product: e });
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>{translate.additional_price}</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputField
              type={'number'}
              value={price}
              disabled={disabled}
              required
              onChange={(e) => {
                onChange({ ...value, price: e?.target?.value });
              }}
              inputProps={{
                min: 0,
                step: 0.01,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={'true'}>
        <Grid container>
          <Grid item xs={12}>
            <Typography>{translate.print_kitchen_tickets || '列印廚房單'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <SwitchField
              disabled={disabled}
              checked={!(hashtags || []).includes('::noprint')}
              onChange={(e, v) => {
                onChange({
                  ...value,
                  hashtags: !v
                    ? (hashtags || []).concat('::noprint')
                    : (hashtags || []).filter((hashtag) => hashtag !== '::noprint'),
                });
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={'auto'}>
        <TrashButton disabled={disabledDelete || disabled} onClick={() => onChange()} />
      </Grid>
    </Grid>
  );
};

export default BundleProductItem;
