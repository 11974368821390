import React from 'react';
import Coupons from './Coupons';
import DiscountCode from './DiscountCode';
import Discounts from './Discounts';
import Taber from 'components/Taber';
import { translate } from 'shared/translate';
import { Box } from '@material-ui/core';

export default (props) => {
  const { location, history } = props,
    { pathname } = location || {};

  const tabs = [
    {
      name: translate.discount_code,
      link: '/discount_code',
    },
    {
      name: translate.coupon_promotion_code,
      link: '/coupons',
    },
    {
      name: translate.discounts,
      link: '/discounts',
    },
  ];

  const tab = -1 * (~tabs.findIndex((tab) => tab.link === pathname) || -1) - 1;

  return (
    <div>
      <Taber
        currentTab={tab}
        onTabChange={(tab) => {
          const { link } = tabs[tab] || {};
          history.push(link);
        }}
        tabs={tabs}
      />
      <Box>
        {
          {
            0: <DiscountCode {...props} filter={{ isRedeemable: { operator: 'IN', value: ['false'] } }} />,
            1: <Coupons {...props} filter={{ isRedeemable: { operator: 'IN', value: ['true'] } }} />,
            2: <Discounts {...props} />,
          }[tab]
        }
      </Box>
    </div>
  );
};
