import React from 'react';
import ConfigCard from '../ConfigCard';
import PreviewCard from '../PreviewCard';
import SplitCard from '../SplitCard';
import EmailWelcome from '../EmailWelcome';
import moment from 'moment/moment';
import defaultSuggestions from '../defaultSuggestions';
import { getCurrency } from '../../../shared';

export const commonOrderSuggestions = [
  ...defaultSuggestions,
  {
    label: '#order.id',
    value: '{{order.id}}',
  },
  {
    label: '#order.referenceNo',
    value: '{{order.referenceNo}}',
  },
  {
    label: '#order.createAt',
    value: '{{order.createdAt}}',
  },
  {
    label: '#order.currency',
    value: '{{order.currency}}',
  },
  {
    label: '#order.subtotal',
    value: '{{order.subtotal}}',
  },
  {
    label: '#order.total',
    value: '{{order.total}}',
  },
  {
    label: '#order.paymentMethods',
    value: '{{order.paymentMethods}}',
  },
  {
    label: '#order.updatedAt',
    value: '{{order.updatedAt}}',
  },
  {
    label: '#order.shippingProvider.name',
    value: '{{order.shippingProvider.name}}',
  },
  {
    label: '#order.shippingAddress.person',
    value: '{{order.shippingAddress.person}}',
  },
  {
    label: '#order.shippingAddress.lines1',
    value: '{{order.shippingAddress.lines.0}}',
  },
  {
    label: '#order.shippingAddress.lines2',
    value: '{{order.shippingAddress.lines.1}}',
  },
  {
    label: '#order.shippingAddress.lines3',
    value: '{{order.shippingAddress.lines.2}}',
  },
  {
    label: '#order.shippingAddress.country',
    value: '{{order.shippingAddress.country}}',
  },
  {
    label: '#deliveryNote.trackingNumber',
    value: '{{deliveryNote.trackingNumber}}',
  },
];

export default class EmailOrderConfirm extends EmailWelcome {
  emailType = 'ORDER_CONFIRMATION';

  state = {
    ...this.state,
    cards: [
      (props) => (
        <SplitCard
          configCard={
            <ConfigCard
              suggestions={commonOrderSuggestions}
              allows={['isTitle', 'isText', 'isImage', 'isDivider', 'isButton', 'isLink', 'isQRCode']}
              {...props}
            />
          }
          previewCard={
            <PreviewCard
              extraData={{
                order: {
                  id: '00000000-0000-0000-0000-000000000000',
                  referenceNo: 'PO000000',
                  createdAt: moment().format('DD/MM/YYYY HH:mm:ss'),
                  updatedAt: moment().format('DD/MM/YYYY HH:mm:ss'),
                  subtotal: '300.00',
                  total: '350.00',
                  paymentMethods: '信用卡支付',
                  shippingProvider: {
                    name: 'SF Express',
                  },
                  shippingAddress: {
                    person: 'Kathy Chan',
                    lines: ['Flat 25, Block A, 10/F, Acacia Building, 150 Kennedy Road, Wan Chai'],
                    country: 'HKG',
                  },
                  currency: getCurrency(),
                  totalAdjustments: [
                    {
                      description: '運費',
                      amount: '100.00',
                    },
                    {
                      description: '優惠券',
                      amount: '-50.00',
                    },
                  ],
                },
                items: [
                  {
                    description: '測試商品',
                    quantity: 2,
                    unitPrice: 100,
                    total: 200,
                    sku: '0000000C100000L',
                    media: {
                      src: 'https://assets.omniwe.com/7X0IO45MpkN8FuAnRXSaV.png',
                    },
                    remark: '這是備註訊息',
                  },
                  {
                    media: {
                      src: 'https://assets.omniwe.com/7X0IO45MpkN8FuAnRXSaV.png',
                    },
                    description: '組合商品',
                    quantity: 1,
                    unitPrice: 100,
                    total: 100,
                    bundleProduct: {
                      bundleProduct: {
                        sku: 'BP00000000000L',
                      },
                      options: [
                        {
                          label: '選項 1',
                          product: {
                            name: '測試商品A',
                            medias: [
                              {
                                src: 'https://assets.omniwe.com/7X0IO45MpkN8FuAnRXSaV.png',
                              },
                            ],
                          },
                          variation: {
                            sku: 'V00000000001',
                          },
                        },
                        {
                          label: '選項 2',
                          product: {
                            name: '測試商品B',
                            medias: [
                              {
                                src: 'https://assets.omniwe.com/7X0IO45MpkN8FuAnRXSaV.png',
                              },
                            ],
                          },
                          variation: {
                            sku: 'V00000000002',
                          },
                        },
                      ],
                    },
                  },
                ],
              }}
              {...props}
            />
          }
        />
      ),
    ],
  };
}
