import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import FormRow from '../../FormRow';
import { translate } from '../../../shared/translate';
import InputField from '../../FormPageMaker/Fields/InputField';
import SystemButton from '../../SystemButton';
import { Icon } from '../../IconRender';
import { addValue2Metadata, convertMetaObject, metadataDeleteKey } from '../../../shared';
import _ from 'lodash';
import TrashButton from '../../TrashButton';

const useStyles = makeStyles((theme) => ({
  addRemarksBtn: {
    '&.Mui-disabled': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const SettingCommon = ({ useFieldKey, disabled, value, onChange = (_) => _ }) => {
  const classes = useStyles();
  const { metadata } = value ?? {};
  const { remarks } = convertMetaObject(metadata);
  return (
    <Box p={2} bgcolor={'#F5F5F5'}>
      <Grid container spacing={1}>
        <FormRow dense title={translate.heading} md={useFieldKey ? 9 : 12}>
          <InputField
            disabled={disabled}
            value={value?.name}
            onChange={(e) =>
              onChange({
                ...value,
                key: useFieldKey ? value?.key : e.target.value,
                name: e.target.value,
              })
            }
          />
        </FormRow>
        {!!useFieldKey && (
          <FormRow dense tooltip={translate.internal_use} title={translate.field_key} required md={3}>
            <InputField
              required
              disabled={disabled}
              value={value?.key}
              onChange={(e) =>
                onChange({
                  ...value,
                  key: e.target.value,
                })
              }
            />
            <Typography variant={'body2'}>{translate.cannot_entirely_numeric || '不能全部是數字'}</Typography>
          </FormRow>
        )}
        <Grid item xs={12}>
          <SystemButton
            className={classes.addRemarksBtn}
            color={'default'}
            variant={'text'}
            onClick={() => {
              onChange({
                ...value,
                metadata: addValue2Metadata('remarks', '', metadata),
              });
            }}
            disabled={disabled || !_.isNil(remarks)}
            startIcon={<Icon icon={'faPlus'} />}
          >
            {translate.add_remarks}
          </SystemButton>
        </Grid>
        {!_.isNil(remarks) && (
          <FormRow dense title={translate.remark}>
            <Grid container spacing={1} alignItems={'center'}>
              <Grid item xs={true}>
                <InputField
                  disabled={disabled}
                  value={remarks}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      metadata: addValue2Metadata('remarks', e.target.value, metadata),
                    })
                  }
                />
              </Grid>
              <Grid item>
                <TrashButton
                  disabled={disabled}
                  onClick={() =>
                    onChange({
                      ...value,
                      metadata: metadataDeleteKey('remarks', metadata),
                    })
                  }
                />
              </Grid>
            </Grid>
          </FormRow>
        )}
      </Grid>
    </Box>
  );
};

export default SettingCommon;
