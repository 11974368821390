import React from 'react';
import { InputAdornment } from '@material-ui/core';
import InputField from '../../../components/FormPageMaker/Fields/InputField';

const FixedChargeField = ({ value: expressions = [], onChange = (expressions) => {}, disabled }) => {
  return (
    <InputField
      disabled={disabled}
      required={true}
      value={expressions[0]?.unitPrice}
      onChange={(e) => {
        onChange([
          {
            unitPrice: e.target.value,
          },
        ]);
      }}
      // eslint-disable-next-line
      InputProps={{
        startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
      }}
    />
  );
};

export default FixedChargeField;
