import CouponList from '../Coupons';
import { translate } from '../../../shared/translate';
import { itemCouponCodeField } from '../../item_Coupon/itemCouponCodeField';
import MenuActivate from '../Coupons/MenuActivate';
import MenuDeactivate from '../Coupons/MenuDeactivate';
import MenuDelete from '../Coupons/MenuDelete';

export default class extends CouponList {
  type = translate.discount_code;
  state = {
    ...this.state,
    menus: [MenuActivate, MenuDeactivate, MenuDelete],
    createUrl: '/discount_code/new',
    getRowLink: ({ id, name }) => ({
      pathname: '/discount_code/' + id,
      state: { title: name },
    }),
    fields: itemCouponCodeField.bind(this)(),
  };
}
