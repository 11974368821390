import React from 'react';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { translate } from '../../shared/translate';
import InputField from '../../components/FormPageMaker/Fields/InputField';

const ConditionField = ({ values: { upgradeConditions } = {}, setFieldValue, loading, isSubmitting }) => {
  const singlePurchaseCondition = upgradeConditions.find((cond) => cond.type === 'SINGLE_PURCHASE') || {
    type: 'SINGLE_PURCHASE',
  };
  const cumulateCondition = upgradeConditions.find((cond) => cond.type === 'SPECIFIC_PERIOD') || {
    type: 'SPECIFIC_PERIOD',
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item>
            <Typography>{translate.single_shopping}</Typography>
          </Grid>
          <Grid item>
            <InputField
              loading={loading}
              disabled={isSubmitting}
              type={'number'}
              value={singlePurchaseCondition.upgradeConditionAmount}
              onChange={(e) => {
                singlePurchaseCondition.upgradeConditionAmount = e.target.value;
                setFieldValue('upgradeConditions', [singlePurchaseCondition, cumulateCondition]);
              }}
              // eslint-disable-next-line
              inputProps={{
                step: 0.01,
                min: 1,
              }}
              // eslint-disable-next-line
              InputProps={{
                startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item>
            <Typography>{translate.or}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item>
            <Typography>{translate.specified_period}</Typography>
          </Grid>
          <Grid item>
            <InputField
              loading={loading}
              disabled={isSubmitting}
              type={'number'}
              value={cumulateCondition.upgradeConditionMonths}
              onChange={(e) => {
                cumulateCondition.upgradeConditionMonths = e.target.value;
                setFieldValue('upgradeConditions', [singlePurchaseCondition, cumulateCondition]);
              }}
              // eslint-disable-next-line
              inputProps={{ step: 1, min: 1 }}
              // eslint-disable-next-line
              InputProps={{
                endAdornment: <InputAdornment position={'end'}>月</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item>
            <Typography>{translate.total_consumption}</Typography>
          </Grid>
          <Grid item>
            <InputField
              loading={loading}
              disabled={isSubmitting}
              type={'number'}
              value={cumulateCondition.upgradeConditionAmount}
              onChange={(e) => {
                cumulateCondition.upgradeConditionAmount = e.target.value;
                setFieldValue('upgradeConditions', [singlePurchaseCondition, cumulateCondition]);
              }}
              // eslint-disable-next-line
              inputProps={{
                step: 0.01,
                min: 1,
              }}
              // eslint-disable-next-line
              InputProps={{
                startAdornment: <InputAdornment position={'start'}>$</InputAdornment>,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConditionField;
