import { gql } from '@apollo/client';
export const FRAGMENT_STOCK_TRANSFER = gql`
  fragment FRAGMENT_STOCK_TRANSFER on CompanyStockTransfer {
    id
    createdAt
    updatedAt
    inboundWarehouse {
      id
      name
    }
    outboundWarehouse {
      id
      name
    }
    referenceNo
    staff {
      id
      name
    }
    status
    totalQuantity
    totalReceivedQuantity
    itemsV2 @client
  }
`;

export const GET_QUERY = gql`
  query (
    $id: ID!
    $cursor: Int
    $filter: StockTransferFilterInput
    $query: String
    $limits: Int
    $sortBy: [SorterInput!]
  ) {
    node(id: $id) {
      id
      ... on Company {
        id
        stockTransfers(query: $query, cursor: $cursor, filter: $filter, limits: $limits, sortBy: $sortBy) {
          nextCursor
          totalCount
          nodes {
            ...FRAGMENT_STOCK_TRANSFER
          }
        }
      }
    }
  }
  ${FRAGMENT_STOCK_TRANSFER}
`;
