import { gql } from '@apollo/client';

export const GET_LOGIN_DATA = gql`
  query {
    me {
      ... on User {
        id
        email
        shops(limits: 1) {
          totalCount
          nodes {
            id
            type
            stockWarehouse {
              id
            }
            company {
              id
            }
            currency
          }
        }
      }
    }
  }
`;
