import { client } from 'shared/apollo';
import { translate } from 'shared/translate';
import FormPageMaker from '../../components/FormPageMaker';
import { CREATE_QUERY, GET_QUERY, POST_QUERY, REMOVE_QUERY } from './query';
import React from 'react';
import {
  cloneObject,
  convertMetaArray,
  convertMetaObject,
  parseAddress,
  removeTypename,
  toInputMedia,
} from '../../shared';
import LocationRulesList from './LocationRulesList';
import { Skeleton } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';

export default class extends FormPageMaker {
  state = {
    ...this.state,
    gql: {
      prepare: GET_QUERY,
      submit: !!this.state.id ? POST_QUERY : CREATE_QUERY,
      remove: REMOVE_QUERY,
    },
    tabs: [
      {
        name: translate.service_location_info,
        cards: [
          {
            fields: [
              {
                label: translate.name,
                type: 'text',
                maxLength: 25,
                name: 'name',
                required: true,
                md: 6,
                block: true,
              },
              {
                label: translate.image,
                type: 'medias',
                name: 'medias',
              },

              {
                md: 6,
                label: translate.contact_person,
                type: 'text',
                name: 'address.person',
              },
              {
                md: 6,
                label: translate.contact_number,
                type: 'mobile',
                name: 'address.tel',
              },
              {
                md: 6,
                label: translate.email,
                type: 'email',
                name: 'address.email',
              },
              {
                md: 6,
                type: 'span',
              },
              {
                md: 6,
                label: translate.address,
                type: 'lines',
                name: 'address.lines',
                numberOfLines: 2,
              },
              {
                md: 6,
                type: 'span',
              },
              {
                md: 6,
                label: '_',
                type: 'district',
                name: 'address.district',
                description: translate.address_lines_remark3,
                formRowStyle: { marginTop: -55 },
                required: ({ values }) => !!parseAddress(values?.address, true),
              },
              {
                md: 6,
                type: 'span',
              },
              {
                md: 6,
                block: true,
                label: translate.sort_index,
                description: translate.display_order_description,
                type: 'number',
                name: 'sortIndex',
                inputProps: {
                  step: 1,
                },
              },
            ],
          },
        ],
      },
      {
        name: translate.service_location_schedule_rules || '場所日程規則',
        noPadding: true,
        cards: [
          (props) =>
            !!this.state.id ? (
              <LocationRulesList id={this.state.id} {...props} />
            ) : (
              <Box p={5} textAlign={'center'}>
                <Typography>{translate.create_service_location_first || '請先建立場所，才能更改日程規則'}</Typography>
              </Box>
            ),
        ],
      },
    ].filter(Boolean),
  };

  submit = async (values) => {
    const {
      id,
      gql: { submit },
    } = this.state;

    const input = {
      address: (() => {
        const address = parseAddress(values.address, true);
        if (address) return { ...address, country: 'HKG' };
        return undefined;
      })(),
      shopId: !!id ? undefined : localStorage.getItem('shopId'),
      name: values.name,
      sortIndex: values.sortIndex,
      medias: values.medias?.map(toInputMedia)?.filter((_) => _),
      metadata: convertMetaArray(values.metadata),
    };

    const {
      data: { result = {} },
    } = await client.mutate({
      mutation: submit,
      variables: {
        id,
        input,
      },
    });
    return true;
  };

  getInitialData(data) {
    const { node } = data || {},
      { id, name = '', sortIndex = 0, updatedAt, tables, medias, metadata, address } = node || {};
    return {
      __calendarTab: 0,
      id,
      name,
      sortIndex,
      updatedAt,
      metadata: convertMetaObject(metadata),
      medias: removeTypename(cloneObject(medias || [])),
      address: removeTypename(cloneObject(address)),
    };
  }
}
