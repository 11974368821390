import React from 'react';
import ConnectionPageMaker from 'components/ConnectionPageMaker';
import { GET_QUERY } from './query';
import { Typography } from '@material-ui/core';
import { AppointmentStatus } from '../../../shared/omniwe-types';
import StatusBadge from '../../../components/StatusBadge';

export default class extends ConnectionPageMaker {
  state = {
    ...this.state,
    gql: {
      get: GET_QUERY,
    },
    createUrl: '/appointments/create',
    getRowLink: ({ id, referenceNo }) => ({
      pathname: `/appointments/${id}`,
      state: { title: referenceNo },
    }),
    fields: [
      {
        title: '預約時間',
        fieldName: 'startedAt',
        type: 'datetime',
        filter: 'startedAt',
      },
      {
        title: '預約地點',
        value: (row) => row?.location?.name,
        filter: 'serviceLocationId',
        filterType: 'serviceLocation',
      },
      {
        width: 250,
        title: '服務',
        value: (row) => (
          <>
            <Typography variant={'h6'} component={'span'}>
              {row?.serviceBundle?.name}
            </Typography>
            <br />
            {row?.service?.name}
          </>
        ),
        filter: 'serviceBundleId',
        filterType: 'serviceBundle',
      },
      {
        title: '預約編號',
        value: (row) => row?.referenceNo,
        filter: 'referenceNo',
        type: 'text',
      },
      {
        title: '訂單編號',
        value: (row) => row?.order?.referenceNo,
        filter: 'orderId',
        filterType: 'order',
      },
      {
        title: '選項',
        value: (row) => row?.orderItem?.remark,
      },
      {
        title: '預約人',
        render: (row) => (
          <Typography style={{ whiteSpace: 'pre' }}>
            {[row?.contactAddress?.person, row?.contactAddress?.email, row?.contactAddress?.tel]
              .filter(Boolean)
              .join('\n')}
          </Typography>
        ),
      },
      {
        title: '狀態',
        fieldName: 'status',
        type: 'options',
        options: Object.keys(AppointmentStatus).map((key) => ({
          label: <StatusBadge status={key} statusObject={AppointmentStatus} />,
          value: key,
        })),
        filter: 'status',
      },
    ],
  };
  getExtraFetchVariables() {
    return { id: localStorage.getItem('shopId') };
  }
  getData = (data) => {
    return data?.node?.appointments;
  };

  getFilter(...args) {
    return {
      startedAt: {
        operator: 'NIN',
        value: [null],
      },
      ...super.getFilter(...args),
    };
  }
}
