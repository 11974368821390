import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import Message from './Message';
import { CircularProgress, Tooltip, Typography, useTheme } from '@material-ui/core';
import { translate } from '../../shared/translate';
import { Icon } from '../IconRender';
import { isStaffPlayer } from './ChannelCustomerDetail';

export default ({ player = {}, players = [], messages = [], pendingMessages = [] }) => {
  return (
    <Box display={'flex'} flexDirection={'column-reverse'} width={'100%'} overflow={'hidden'}>
      {pendingMessages.map(({ id, type, content }, i) => (
        <MessageWrapper key={id || i} isMe={true} type={type} content={content} />
      ))}
      {messages.map(({ id, type, channelPlayerId, content, createdAt }, i) => {
        const isAPIUser = channelPlayerId === '00000000-0000-0000-0000-000000000000';
        const isMe = isStaffPlayer((players || []).find((player) => player?.id === channelPlayerId));

        return (
          <MessageWrapper
            key={id}
            isMe={isMe}
            isAPIUser={isAPIUser}
            type={type}
            content={content}
            createdAt={createdAt}
            nextCreatedAt={messages[i + 1]?.createdAt}
          />
        );
      })}
    </Box>
  );
};

const MessageWrapper = ({ isAPIUser, isMe, type, content, createdAt, nextCreatedAt }) => {
  const theme = useTheme();
  return (
    <Box my={1} px={3} py={1}>
      {!!createdAt && (
        <>
          {/* 時間分隔： group by day */}
          {!!nextCreatedAt ? (
            moment(nextCreatedAt).startOf('days').format('yyyy-MM-DD') !==
            moment(createdAt).startOf('days').format('yyyy-MM-DD') ? (
              <div style={{ textAlign: 'center' }}>
                <Typography
                  variant={'caption'}
                  align={'center'}
                  style={{ backgroundColor: theme.palette.primary.contrastText, padding: '8px', borderRadius: '10px' }}
                >
                  {moment(createdAt).format('DD/MM YYYY')}
                </Typography>
              </div>
            ) : (
              <></>
            )
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Typography
                variant={'caption'}
                align={'center'}
                style={{ backgroundColor: theme.palette.primary.contrastText, padding: '8px', borderRadius: '10px' }}
              >
                {moment(createdAt).format('DD/MM YYYY')}
              </Typography>
            </div>
          )}
          {/* 時間分隔: group by day */}
        </>
      )}
      <>
        <Grid container direction={isMe || isAPIUser ? 'row-reverse' : 'row'}>
          <Grid item>
            <Message type={type} content={content} isMe={isMe || isAPIUser} />
          </Grid>
        </Grid>
        <Grid container direction={isMe || isAPIUser ? 'row-reverse' : 'row'} alignItems={'center'} spacing={1}>
          <Grid item>
            {createdAt ? (
              <Typography variant={'overline'}>{moment(createdAt).format('HH:mm a')}</Typography>
            ) : (
              <CircularProgress size={10} color={'inherit'} />
            )}
          </Grid>
          {isAPIUser && (
            <Grid item>
              <Tooltip title={translate.system_message}>
                <Icon icon={'faRobot'} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </>
    </Box>
  );
};
