import React, { useState, useEffect } from 'react';
import { Box, ButtonBase, Chip, InputAdornment, Typography } from '@material-ui/core';
import { Icon } from '../../IconRender';
import ImageFieldModal from './ImageFieldModal';
import FormRow from '../../FormRow';
import InputField from './InputField';
import Dropzone from 'react-dropzone';
import toast from '../../../shared/toast';
import errorParser from '../../../shared/errorParser';
import s3Upload from '../../../shared/s3Upload';
import uuid from 'react-uuid';
import OverlayLoading from '../../OverlayLoading';
import LSkeleton from '../../LSkeleton';
import MagicMedia from '../../MagicMedia';
import MagicMediaDialogButton from '../../MagicMediaButton/MagicMediaDialogButton';
import useBreakPoint from '../../useBreakPoint';
import { checkPermissionV2 } from '../../PermissionMask';
import { translate } from '../../../shared/translate';

const MagicMediaField = ({
  loading: _loading,
  required,
  className,
  disabled,
  modifyDisabled,
  value,
  hideRemoveButton,
  onChange = (_) => _,
}) => {
  const [hover, setHover] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false),
    toggleModal = () => setOpen(!open);
  const breakPoint = useBreakPoint();

  const { id, alt, src, optimizedSrc } = value || {};

  useEffect(() => {
    if (!!useEffect && !id) {
      onChange({
        ...value,
        id: uuid(),
      });
    }
  }, [value]);

  const onFileDrop = async (files) => {
    try {
      setIsDragging(false);
      setLoading(true);
      let file = files[0];
      const src = await new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = (e) => {
          reject(e);
        };
        reader.readAsDataURL(file);
      });
      onChange({
        ...value,
        src,
        optimizedSrc: '',
      });
      const s3Path = await s3Upload(file);
      onChange({
        ...value,
        src: s3Path,
        optimizedSrc: '',
      });
    } catch (e) {
      toast.error(errorParser(e));
    } finally {
      setLoading(false);
    }
  };

  if (_loading) return <LSkeleton variant={'rect'} width={100} height={100} />;

  return (
    <Box
      display={'inline-block'}
      onMouseEnter={(e) => {
        setHover(true);
      }}
      onMouseLeave={(e) => {
        setHover(false);
      }}
    >
      <Dropzone
        disabled={disabled || modifyDisabled || loading}
        onDragEnter={() => setIsDragging(true)}
        onDragLeave={() => setIsDragging(false)}
        onDrop={onFileDrop}
        accept={['image/*', 'video/*']}
      >
        {({ getRootProps, getInputProps }) => (
          <ButtonBase
            type={`button`}
            style={{
              width: 100,
              height: 100,
              borderRadius: '1rem',
              overflow: 'hidden',
              border: `1px solid #665858`,
            }}
            {...getRootProps()}
            disabled={disabled || modifyDisabled || loading}
            onClick={toggleModal}
          >
            <input
              type={'file'}
              {...getInputProps()}
              accept="image/*"
              disabled={disabled || modifyDisabled || loading}
              required={required}
            />
            <MagicMedia
              loading={loading}
              disabled={true}
              resizeMode={hover ? 'cover' : 'contain'}
              isPlaceholder={!optimizedSrc && !src}
              src={optimizedSrc || src}
            />
            {(loading || isDragging) && (
              <OverlayLoading>{isDragging ? <Icon icon={'faUpload'} fontSize={'large'} /> : undefined}</OverlayLoading>
            )}
          </ButtonBase>
        )}
      </Dropzone>
      <ImageFieldModal
        open={open}
        onClose={toggleModal}
        onDelete={
          (optimizedSrc || src) &&
          (() => {
            onChange(undefined);
            toggleModal();
          })
        }
        image={src || optimizedSrc}
        onChange={(src) => {
          onChange({
            ...value,
            src,
            optimizedSrc: '',
          });
        }}
      >
        {!disabled && !hideRemoveButton && (optimizedSrc || src) && (
          <>
            <FormRow title={translate.others}>
              {checkPermissionV2('photoroom') && ['md', 'lg', 'xl'].includes(breakPoint) && (
                <Box mt={1}>
                  <MagicMediaDialogButton
                    value={value}
                    onChange={(v) => {
                      onChange(v);
                      toggleModal();
                    }}
                  />
                </Box>
              )}
              <Box mt={1}>
                <InputField
                  value={alt}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">alt</InputAdornment>,
                  }}
                  onChange={(e) => {
                    onChange({
                      ...value,
                      alt: e.target.value,
                    });
                  }}
                />
              </Box>
            </FormRow>
          </>
        )}
      </ImageFieldModal>
    </Box>
  );
};

export default MagicMediaField;
