import React from 'react';
import SaleChart from './SaleChart';
import { translate } from '../../shared/translate';
import { getCurrency, NumberBriefFormat } from '../../shared';

export default ({ loading, amount, count }) => {
  return (
    <SaleChart
      loading={loading}
      title={translate[`last_7_days_sales`]}
      amount1={NumberBriefFormat(amount)}
      label1={`${translate.invoice_amount}(${getCurrency()})`}
      amount2={NumberBriefFormat(count)}
      label2={translate['orders']}
    />
  );
};
