import { gql } from '@apollo/client';

export const FRAGMENT_PRINTABLE_ORDER_ITEM = gql`
  fragment FRAGMENT_PRINTABLE_ORDER_ITEM on OrderItem {
    id
    sku
    createdAt
    serviceBundle {
      serviceBundle {
        id
        name
        sku
      }
      services {
        id
        service {
          id
          name
        }
        location {
          id
          name
        }
        appointment {
          id
          startedAt
          startedThru
        }
      }
    }
    bundleProduct {
      bundleProduct {
        id
        name
        sku
      }
      options {
        id
        remark
        label
        price
        variation {
          id
          sku
          barcode
          combination {
            name
            option
          }
        }
        product {
          id
          name
          printDescription
        }
      }
    }
    addOnProduct {
      id
      sku
      unitPrice
    }
    media {
      src
    }
    productVariation {
      id
      unitPrice
      barcode
      combination {
        name
        option
      }
      sku
      product {
        id
        name
        printDescription
      }
    }
    description
    quantity
    unitPrice
    remark
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ... on ShopOrder {
        shippingProvider {
          id
          name
        }
        shippingAddress {
          name
          person
          tel
          email
          lines
          country
        }
        billingAddress {
          person
          tel
          email
          lines
          country
        }
        referenceNo
        subtotal
        total
        currency
        remark
        taxFee
        shippingFee
        shopDiscount
        couponDiscount
        totalPaid
        totalRefund
        shop {
          id
          name
          locale
          logoMedia {
            src
            optimizedSrc(width: 256, height: 256)
          }
          hostname
          customDomain
        }
        totalAdjustments {
          sortIndex
          amount
          description
        }
        items {
          ...FRAGMENT_PRINTABLE_ORDER_ITEM
        }
        deliveryNotes {
          nodes {
            id
            status
            trackingNumber
          }
        }
        invoices {
          nodes {
            id
            createdAt
            status
            paymentMethodV2 {
              id
              name
              provider
            }
            credential {
              id
              name
            }
            total
            totalPaid
            totalRefund
            change
          }
        }
      }
      createdAt
      updatedAt
    }
  }
  ${FRAGMENT_PRINTABLE_ORDER_ITEM}
`;
