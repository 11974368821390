import { gql } from '@apollo/client';

export const FRAGMENT_TRANSFER = gql`
  fragment FRAGMENT_TRANSFER on CompanyStockTransfer {
    createdAt
    updatedAt
    status
    referenceNo
    completedAt
    remark
    staff {
      id
      name
    }
    sentAt
    inboundWarehouse {
      id
      name
    }
    outboundWarehouse {
      id
      name
    }
    itemsV2 @client
  }
`;

export const GET_QUERY = gql`
  query ($id: ID!) {
    node(id: $id) {
      id
      ...FRAGMENT_TRANSFER
    }
  }
  ${FRAGMENT_TRANSFER}
`;

export const GET_TRANSFER_WAREHOUSE = gql`
  query ($id: ID!) {
    node(id: $id) {
      ... on CompanyShop {
        id
        transferWarehouse {
          id
          name
        }
      }
    }
  }
`;

export const SUBMIT_QUERY = gql`
  mutation ($input: StockTransferCreateInput!) {
    result: stockTransferCreate(input: $input) {
      id
      ...FRAGMENT_TRANSFER
    }
  }
  ${FRAGMENT_TRANSFER}
`;

export const UPDATE_QUERY = gql`
  mutation ($id: ID!, $input: StockTransferUpdateInput!) {
    result: stockTransferUpdate(id: $id, input: $input) {
      id
      ...FRAGMENT_TRANSFER
    }
  }
  ${FRAGMENT_TRANSFER}
`;

export const SEND_QUERY = gql`
  mutation ($id: ID!) {
    stockTransferSend(id: $id) {
      id
      status
      sentAt
    }
  }
`;

export const COMPLETE_QUERY = gql`
  mutation ($id: ID!, $items: [TransferItemInput!]) {
    stockTransferComplete(id: $id, items: $items) {
      id
      completedAt
      status
      itemsV2 @client
    }
  }
`;

export const VOID_QUERY = gql`
  mutation ($id: ID!) {
    stockTransferVoid(id: $id) {
      id
      status
    }
  }
`;

export const IMPORT_QUERY = gql`
  mutation ($id: ID!, $shopId: ID!, $url: AWSURL!) {
    stockTransferImport(id: $id, shopId: $shopId, url: $url) {
      id
      status
    }
  }
`;
