import React from 'react';
import FormCard from '../../../components/FormPageMaker/Cards/FormCard';

export default {
  platform: {
    icon: <img className="h-4" src={require('../../../assets/logo_keeta.png')} alt="Keeta" />,
    label: 'Keeta',
    value: 'KEETA',
  },
  form: (actions) => {
    return (
      <FormCard
        variant={'outlined'}
        actions={actions}
        fields={[
          {
            label: 'Shop ID',
            type: 'text',
            name: 'identity',
            required: true,
            maxLength: 255,
          },
          {
            label: 'Access Token',
            type: 'text',
            name: 'metadata.accessToken',
            required: true,
            maxLength: 255,
          },
          {
            label: 'Refresh Token',
            type: 'text',
            name: 'metadata.refreshToken',
            required: true,
            maxLength: 255,
          },
        ]}
      />
    );
  },
  type: 'AUTHENTICATION',
};
